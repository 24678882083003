import React from 'react';

import { Container } from 'layout';
import PurposeBanner from 'components/PurposeBanner';
import MediaBanner from 'components/MediaBanner';
import CampaignSimpleBanner from 'components/CampaignSimpleBanner';
import GridList from 'components/GridList';
import DescriptionGridLink from 'common/DescriptionGridLink';
import CustomCarousel from 'components/CustomCarousel';
import DescriptionLinkVideo from 'common/DescriptionLinkVideo';
import InfoBanner from 'components/InfoBanner';
import WideBanner from 'components/WideBanner';
import TextSection from 'components/TextSection';
import SimpleBanner from 'components/SimpleBanner';
import InstructionsStepList from 'components/InstructionsStepList';
import SignUpBlock from 'components/SignUpBlock';
import ImageSection from 'components/ImageSection';
import ReceiptForm from 'components/ReceiptForm';
import SignUp from 'components/SignUp';
import VideoListSection from 'components/VideoListSection';
import RelatedArticles from 'components/RelatedArticles';
import CampaignBanner from 'components/CampaignBanner';
import IframeBlock from 'components/IframeBlock';
import FaqSection from 'components/FaqSection';
import SlimBanner from 'components/SlimBanner';
import DownloadList from 'components/DownloadList';
import ImageMapSection from 'components/ImageMapSection';
import ProductListingBanner from 'components/ProductListingBanner';
import CouponsSections from 'components/CouponsSections';
import PledgeSection from 'components/PledgeSection';
import WaterCalculator from 'components/WaterCalculator';
import SectionWithCarousel from 'components/SectionWithCarousel';
import QuestionsCarousel from 'components/SupportPage/QuestionsCarousel';
import QuestionsBlock from 'components/SupportPage/QuestionsBlock';
import VideoBackgroundSection from 'components/VideoBackgroundSection';
import WaterBanner from 'components/WaterBanner';
import EBook from 'components/EBook';
import PromoForm from 'components/PromoForm';
import ImageBanner from 'components/ImageBanner';
import RelatedCampaigns from 'components/RelatedCampaigns';
import BazaarvoiceReviewsSection from 'components/BazaarvoiceReviewsSection';
import HomeBanner from 'components/HomeBanner';
import CommunityConnectSection from 'components/CommunityConnectSection';
import ChallengeBanner from 'components/ChallengeBanner';
import RetailersSection from 'components/RetailersSection';
import Video from 'common/Video';
import TextBlock from 'common/TextBlock';
import SuperbowlBanner from 'components/SuperBowlBanner';
import VoteSection from 'components/VoteSection';
import ChallengeInfoSection from 'components/ChallengeInfoSection';
import WaterWasteBanner from 'components/WaterWasteBanner';
import WaterWasteVideo from 'components/WaterWasteVideo';
import SaveWaterProducts from 'components/SaveWaterProducts';
import RteSection from 'components/RteSection';
import CampaignCards from 'components/CampaignCards';
import ResiduesSection from 'components/ResiduesSection';
import GuideSection from 'components/GuideSection';
import HowTheMathWorks from 'components/HowTheMathWorks';
import WaterChallengeBanner from 'components/WaterChallengeBanner';
import ImageDivider from 'components/ImageDivider/ImageDivider';
import LinksSection from 'components/LinksSection';
import BrandsSection from 'components/BrandsSection';
import WaterReport from 'components/WaterReport';

export const bannerElements = {
  'Top Banner': ({ properties }, keyId) => <SimpleBanner key={keyId} {...properties} />,
  'Purpose Banner': ({ properties }, keyId) => <PurposeBanner key={keyId} {...properties} />,
  'Simple Banner': ({ properties }, keyId) => (
    <CampaignSimpleBanner
      key={keyId}
      background={properties.background}
      header={properties.header}
    />
  ),
  'Campaign Banner': ({ properties }, keyId) => <CampaignBanner key={keyId} {...properties} />,
  'Water Banner': ({ properties }, keyId) => <WaterBanner key={keyId} {...properties} />,
};

export const elements = {
  'Image Banner': ({ properties }, keyId) => <ImageBanner key={keyId} {...properties} />,
  'New Media Banner': ({ properties }, keyId) => <MediaBanner key={keyId} {...properties} />,
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Image Section': ({ properties }, keyId) => <ImageSection key={keyId} {...properties} />,
  'Image Text Grid': ({ properties }, keyId) => <GridList key={keyId} {...properties} />,
  Partners: ({ properties }, keyId) => <DescriptionGridLink key={keyId} {...properties} />,
  Carousel: ({ properties }, keyId) => <CustomCarousel key={keyId} {...properties} />,
  'Description Link Video': ({ properties }, keyId) => (
    <DescriptionLinkVideo key={keyId} {...properties} />
  ),
  'Info Banner': ({ properties }, keyId) => <InfoBanner key={keyId} {...properties} />,
  'Info Banner Wide': ({ properties }, keyId) => <WideBanner key={keyId} {...properties} />,
  'Text Section': ({ properties }, keyId) => <TextSection key={keyId} {...properties} />,
  'Instructions List': ({ properties }, keyId) => (
    <InstructionsStepList key={keyId} properties={properties} />
  ),
  'Sign Up Block': ({ properties }, keyId) => <SignUpBlock key={keyId} {...properties} />,
  'Receipt Form': ({ properties }, keyId) => <ReceiptForm key={keyId} {...properties} />,
  'Video List Section': ({ properties }, keyId) => <VideoListSection key={keyId} {...properties} />,
  'Related Articles': ({ properties }, keyId) => <RelatedArticles key={keyId} {...properties} />,
  'Image Map Section': ({ properties }, keyId) => <ImageMapSection key={keyId} {...properties} />,
  'Iframe Block': ({ properties }, keyId) => <IframeBlock key={keyId} {...properties} />,
  'Promo Form': ({ properties }, keyId) => <PromoForm key={keyId} {...properties} />,
  'FAQ Section': ({ properties }, keyId) => <FaqSection key={keyId} {...properties} />,
  'Slim banner': ({ properties }, keyId) => <SlimBanner key={keyId} {...properties} />,
  'Download List': ({ properties }, keyId) => <DownloadList key={keyId} {...properties} />,
  'Product Listing Banner': ({ properties }, keyId) => (
    <ProductListingBanner key={keyId} {...properties} />
  ),
  'Coupons Section': ({ properties }, keyId) => <CouponsSections key={keyId} {...properties} />,
  'Pledge Section': ({ properties }, keyId) => <PledgeSection key={keyId} {...properties} />,
  'Video Background Section': ({ properties }, keyId) => (
    <VideoBackgroundSection key={keyId} {...properties} />
  ),
  'Water Calculator Section': ({ properties }, keyId) => (
    <WaterCalculator key={keyId} {...properties} />
  ),
  EBook: ({ properties }, keyId) => <EBook key={keyId} {...properties} />,
  'Section with carousel': ({ properties }, keyId) => {
    const { text, card, color, carouselMode, button, waiSettings, verticalCardMode } = properties;
    const questionCards = card.map((item) => item.properties);

    return (
      <SectionWithCarousel
        key={keyId}
        text={text}
        color={color}
        cardsBlock={
          carouselMode ? (
            <QuestionsCarousel
              questionCards={questionCards}
              ariaNext={waiSettings?.ariaNext}
              ariaPrev={waiSettings?.ariaPrev}
              isVerticalCardMode={verticalCardMode}
            />
          ) : (
            <QuestionsBlock questionCards={questionCards} isVerticalCardMode={verticalCardMode} />
          )
        }
        button={button}
      />
    );
  },
  'Related Campaigns': ({ properties }, keyId) => <RelatedCampaigns key={keyId} {...properties} />,
  'Bazaarvoice Reviews': ({ properties }, keyId) => (
    <BazaarvoiceReviewsSection
      key={keyId}
      title={properties.title}
      bvProductId={properties.bvProductId}
    />
  ),
  'Home Banner': ({ properties }, keyId) => <HomeBanner key={keyId} {...properties} />,
  'Community Connect Section': ({ properties }, keyId) => (
    <CommunityConnectSection key={keyId} {...properties} />
  ),
  'Challenge Banner': ({ properties }, keyId) => <ChallengeBanner key={keyId} {...properties} />,
  'Retailers Section': ({ properties }, keyId) => <RetailersSection key={keyId} {...properties} />,
  'Text Block': ({ properties }, keyId) => (
    <Container fluid>
      <TextBlock key={keyId} text={properties.text} />
    </Container>
  ),
  Video: ({ properties }, keyId) => (
    <Video className="campaign-page__video" key={keyId} {...properties} />
  ),
  'Super Bowl Banner': ({ properties }, keyId) => <SuperbowlBanner key={keyId} {...properties} />,
  'Vote Section': ({ properties }, keyId) => <VoteSection key={keyId} {...properties} />,
  'Challenge Info Section': ({ properties }, keyId) => (
    <ChallengeInfoSection key={keyId} {...properties} />
  ),
  'Water Waste Banner': ({ properties }, keyId) => <WaterWasteBanner key={keyId} {...properties} />,
  'Water Waste Video': ({ properties }, keyId) => <WaterWasteVideo key={keyId} {...properties} />,
  'Save Water Products': ({ properties }, keyId) => (
    <SaveWaterProducts key={keyId} {...properties} />
  ),
  'Rte Section': ({ properties }, keyId) => <RteSection key={keyId} {...properties} />,
  'Campaign Cards': ({ properties }, keyId) => <CampaignCards key={keyId} {...properties} />,
  'Residues Section': ({ properties }, keyId) => <ResiduesSection key={keyId} {...properties} />,
  'Guide Section': ({ properties }, keyId) => <GuideSection key={keyId} {...properties} />,
  'How The Math Works': ({ properties }, keyId) => <HowTheMathWorks key={keyId} {...properties} />,
  'Water Challenge Banner': ({ properties }, keyId) => (
    <WaterChallengeBanner key={keyId} {...properties} />
  ),
  'Image Divider': ({ properties }, keyId) => <ImageDivider key={keyId} {...properties} />,
  'Links Section': ({ properties }, keyId) => <LinksSection key={keyId} {...properties} />,
  'Brands Section': ({ properties }, keyId) => <BrandsSection key={keyId} {...properties} />,
  'Water Report': ({ properties }, keyId) => <WaterReport key={keyId} {...properties} />,
};
