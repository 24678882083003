import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';

import { IHowTheMathWorksProps } from './model';

import './HowTheMathWorks.scss';

const HowTheMathWorks: FC<IHowTheMathWorksProps> = ({
  title,
  costTitle,
  costs,
  math,
  footnote,
}) => (
  <section className="math">
    <DangerouslySetInnerHtml className="math__title" html={title} />
    <div className="math__cost">
      <div className="math__cost-container">
        <DangerouslySetInnerHtml className="math__cost-title" html={costTitle} />
        <ul className="math__cost-list">
          {costs.map(({ properties: { title: costItemTitle, image } }) => (
            <li key={costItemTitle} className="math__cost-list-item">
              <GatsbyImage
                image={image[0].properties.image}
                alt={image[0].properties.imageAlt}
                objectFit="contain"
              />
              <DangerouslySetInnerHtml className="math__cost-list-title" html={costItemTitle} />
            </li>
          ))}
        </ul>
      </div>
    </div>
    <div className="math__calculation">
      <ul className="math__calculation-list">
        {math.map(({ properties: { title: mathTitle, image: mathImage, numbers } }) => (
          <li key={mathTitle} className="math__calculation-list-item">
            <div className="math__calculation-list-item-image-with-text">
              <DangerouslySetInnerHtml
                className="math__calculation-list-item-title"
                html={mathTitle}
              />
              <GatsbyImage
                image={mathImage[0].properties.image}
                alt={mathImage[0].properties.imageAlt}
                objectFit="contain"
              />
            </div>
            <DangerouslySetInnerHtml
              className="math__calculation-list-item-numbers"
              html={numbers}
            />
          </li>
        ))}
      </ul>
    </div>
    <DangerouslySetInnerHtml className="math__footnote" html={footnote} />
  </section>
);

export const query = graphql`
  fragment FragmentHowTheMathWorks on THowTheMathWorks {
    properties {
      title
      costTitle
      costs {
        properties {
          title
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 180, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
        }
      }
      math {
        properties {
          title
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 512, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
          numbers
        }
      }
      footnote
    }
    structure
  }
`;

export default HowTheMathWorks;
